<template>
   <div>
    <a-form :model="formCus">
      <a-row>
        <a-col :span="24">
          <a-form-item  label="客户姓名" name="name">
            {{formCus.name}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item  label="回访主题" name="returnToTheTopic">
            {{formCus.returnToTheTopic}}
          </a-form-item>
        </a-col>
      </a-row>
      </a-form>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="24">
          <a-form-item label="回访结果" name="outcome" :rules="[{ required: true, message: '请输入回访结果' }, { min: 1, max: 200, message: '只能输入1-200个字符' }]">
            <a-textarea v-model:value="form.outcome" placeholder="请输入备注" style="width: 100%;height: 100px;" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
         <a-form-item label="是否创建下次回访" name="isVisit">
            <a-radio-group v-model:value="isVisit">
              <a-radio value="true">是</a-radio>
              <a-radio value="false">否</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
       <a-row v-if="isVisit == 'true'">
        <a-col :span="24">
          <a-form-item label="下次回访时间" name="estimatedReturnVisitTime" :rules="[{ required: true, message: '请选择下次回访时间' }]">
          <a-date-picker v-model:value="form.estimatedReturnVisitTime" placeholder="请选择下次回访时间" style="width: 50%" :getPopupContainer="triggerNode => {
            return triggerNode.parentNode || document.body;
          }" />
        </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="isVisit == 'true'">
        <a-col :span="24">
          <a-form-item label="回访主题" name="returnToTheTopic" :rules="[{ required: true, message: '请输入回访主题' }, { min: 1, max: 200, message: '只能输入1-200个字符' }]">
            <a-textarea v-model:value="form.returnToTheTopic" placeholder="请输入备注" style="width: 100%;height: 100px;" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">确认</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import dayjs from 'dayjs';
export default {
    name: 'SaasAdminToVisit',
    props: ['selectedRows'],
    data() {
        return {
           submitLoading: false,
           isVisit: "true",
           form: {}, 
           formCus:{}
        };
    },

   created() {
    this.getDetail(this.selectedRows[0].id)
  },

    methods: {
     async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/retention/customerReturnVisits/returnVisit/get", { id: id })
        if (res.code == '200') {
          this.formCus = res.source
        }
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let VisitTime=''
      if(this.isVisit=='true'){
         VisitTime  = dayjs(this.form.estimatedReturnVisitTime).format('YYYY-MM-DD HH:mm:ss');
      }
      let {returnToTheTopic,estimatedReturnVisitTime,outcome}=this.form
      try {
        await this.$http.post('/retention/customerReturnVisits/returnVisit', {
          returnToTheTopic,estimatedReturnVisitTime:VisitTime,outcome
          ,id:this.selectedRows[0].id});
        this.$message.success('操作成功');
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>

</style>